@import url('https://fonts.googleapis.com/css2?family=Jockey+One&family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');
.families{
  font-family: "Jockey One", serif;
  font-family: "Montserrat", serif;
}
/* Prevent horizontal overflow globally */


.background-div{
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  height: 850px;
  position: absolute;
  z-index: -1;
}
.logo{
  width: 150px;
}
.not-found{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.back{
  width: 100%;
  height: 100%;
  object-fit: cover;
  filter: brightness(0.5);
}
.title{
  color: white;
  font-family: "Jockey One", serif;
  font-size: 70px;
  margin-top: -10px;
  width: 600px;
  text-align: center;
  margin-bottom: -10px;

}
.landing-page{
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 100px;
}
.style-element{
  font-family: "Jockey One", serif;
  color: white;
  background-color: #BB133E;
  padding-left: 20px;
  height: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 150px;
  padding-right: 20px;
  border-radius: 100px 5px 100px 5px;
  margin-top: 200px;
}
.style-element-app{
  font-family: "Jockey One", serif;
  color: white;
  background-color: #BB133E;
  padding-left: 20px;
  height: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 150px;
  padding-right: 20px;
  border-radius: 100px 5px 100px 5px;
  margin-top: 50px;
}
.slogan{
  font-family: "Montserrat", serif;
  font-weight: bold;
  width: 600px;
  text-align: center;
  color: white;
}
.main-buttons{
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 30px;

}
.learn-more{
  background-color: #BB133E;
  color: white;
  font-family: "Montserrat", serif;
  font-weight: bold;
  font-size: 20px;
  width: 150px;
  height: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  border: none;
  transition: 0.2s ease-in-out;

}
.learn-more:hover{
  background-color: #d3204d;
  cursor: pointer;
  transition: 0.2s ease-in-out;

}
.apply{
  background-color: #002664;
  color: white;
  font-family: "Montserrat", serif;
  font-weight: bold;
  font-size: 20px;
  width: 150px;
  height: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  border: none;
}
.apply:hover{
  background-color: #0e3b83;
  cursor: pointer;
  transition: 0.2s ease-in-out;
}
.header{
  display: flex;
  flex-direction: row;
  align-items: center;
  position: fixed;
  justify-content: space-between;
  width: 100%;
  left: 0;
  right: 0;
  height: 100px;
  top: 0;
  padding-left: 20px;
  padding-right: 20px;
  z-index: 2;
  transition: 0.2s ease-in-out;
}
.header.scrolled {
  transition: 0.2s ease-in-out;
  background-color: white;; /* Change to your desired color */
}
.options-header{
  display: flex;
  flex-direction: row;
  align-items: center;
  font-family: "Montserrat", serif;
  color: white;
  font-weight: bold;
  width: 900px;
  justify-content: space-evenly;
  transition: 0.2s ease-in-out;

}
.button-header{
  padding-right: 30px;
}
.option{
  transition: 0.3s ease-in-out;
  color: white;

}
.option:hover{
  color: #d3204d;
  cursor: pointer;

}
.options-header.scrolled-options .option {
  transition: 0.2s ease-in-out;
  color: #002664; /* Change to the specified color when scrolled */
}
.about-us{
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 200px;
}
.about-us-title{
  font-family: "Jockey One", serif;
  color: #002664;
  font-size: 70px;
  margin-top: -10px;
  margin-bottom: -20px;
}
.staff-title{
  font-family: "Jockey One", serif;
  color: #002664;
  font-size: 60px;
  margin-top: -10px;

}
.staff-title-new{
  font-family: "Jockey One", serif;
  color: #002664;
  font-size: 60px;
  margin-top: -10px;
}

.intro-video {
  width: 800px;
  height: 500px;
  max-width: 1000px; /* Prevents it from exceeding 1000px on large screens */
  border-radius: 20px;
  margin-top: 20px;
}
.information-section-div{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding-left: 30px;
  padding-top: 30px;
}
.right-info{
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 20px;
}
.discover{
  width: 600px;
  font-family: "Jockey One", serif;
  font-size: 40px;
  margin-bottom: -10px;
  color: #002664;
}
.mission{
  font-family: "Montserrat", serif;
  color: black;
  width: 500px;
}
.soccer{
  width: 300px;
}
.tennis,.swimming{
  width: 300px;
}
.two{
  display: flex;
  flex-direction: column;
  row-gap: 20px;
}
.team{
  display: flex;
  flex-direction: column;
  align-items: center;
}
.row-one,.row-two{
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 30px;
  margin-top: 20px;
}
.profile{
  width: 300px;
}
.process{
  display: flex;
  flex-direction: column;
  align-items: center;
}
.small-info{
  font-size: 15px;
  width: 600px;
  text-align: center;
  color: black;
  font-family: "Montserrat", serif;
  margin-top: -20px;
}
.process-div{
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
  row-gap: 20px;
}
.process-actual{
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 900px;
  height: 250px;
  border-bottom: 1px solid rgb(232, 232, 232);
}
.process-left{
  width: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.process-right{
  display: flex;
  width: 700px;
  flex-direction: column;
  align-items: flex-start;
}
.step{
  color: #002664;
  font-family: "Jockey One", serif;
  margin-bottom: -10px;

}
.step-title{
  color: black;
  font-family: "Montserrat", serif;

}
.step-info{
  color: black;
  font-family: "Montserrat", serif;

}
.testimonials{
  display: flex;
  flex-direction: column;
  align-items: center;
}

.testimony{
  width: 300px; /* Set a minimum width to ensure each testimony maintains its width */
  border-radius: 20px;
  background-color: black;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: baseline;
  justify-content: baseline;
  padding-left: 30px;
  margin-left: 20px;
  margin-right: 20px;
  height: 200px;
}



.quote{
  width: 25px;
  padding-top: 10px;
}

.testi{
  font-family: "Montserrat", serif;
  width: 250px;
  font-size: 15px;
}
.contact-us{
  display: flex;
  flex-direction: column;
  align-items: center;
}
.label{
  font-family: "Montserrat", serif;
  font-weight: bold;
  color: black;
  margin-top: 10px;

}
.label-small{
  font-family: "Montserrat", serif;
  font-weight: bold;
  color: black;
  margin-top: 10px;
  font-size: 15px;
}
.contact-form{
  display: flex;
  flex-direction: column;
  align-items: baseline;
}
.input{
  width: 350px;
  height: 40px;
  text-align: center;
  border: none;
  border-radius: 5px;
  background-color: #e0dede;
  margin-top: 10px;
  font-family: "Montserrat", serif;
  font-size: 15px;
}
.input-long{
  width: 430px;
  height: 40px;
  text-align: center;
  border: none;
  border-radius: 5px;
  background-color: #e0dede;
  margin-top: 10px;
  font-family: "Montserrat", serif;
  font-size: 15px;
}
.small-input{
  width: 200px;
  height: 40px;
  text-align: center;
  border: none;
  border-radius: 5px;
  background-color: #e0dede;
  margin-top: 10px;
  font-family: "Montserrat", serif;
  font-size: 15px;
}
.text-area{
  width: 350px;
  height: 100px;
  text-align: center;
  border: none;
  border-radius: 5px;
  background-color: #e0dede;
  margin-top: 10px;
  font-family: "Montserrat", serif;
  font-size: 15px;
}
.button-area{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
}
.testimonial-scroll{
  width: 800px;
  overflow-y: scroll;
  display: flex;
  flex-direction: row;
  column-gap: 20px;
}
.thanks{
  font-size: 20px;
  width: 500px;
  margin-top: -20px;
  text-align: center;
  font-family: "Montserrat", serif;
  color: gray;

}
.call-us{
  font-family: "Montserrat", serif;
  font-weight: bolder;
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 10px;
  color: #002664;
  margin-top: -20px;
}
.form-div{
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 30px;
}
.input-div{
  display: flex;
  flex-direction: column;
  align-items: baseline;
}
.check-div{
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 10px;
}
.phone{
  width: 25px;

}
@media (max-width: 1200px) {
  .information-section-div{
    display: flex;
    flex-direction: column;
  }
  .left-info{
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .mission{
    width: 800px;
    text-align: center;
  }
  .discover{
    width: 800px;
    text-align: center;
  }
  .intro-video{
    width: 900px;
  }
}
@media (max-width: 1000px) {
  .intro-video{
    width: 900px;
  }
  .process-actual{
    display: flex;
    flex-direction: column;
  }
  .mission{
    width: 600px;
    text-align: center;
  }
  .discover{
    width: 600px;
    text-align: center;
  }
  .testimonial-scroll{
    width: 600px;
  }
}
@media (max-width: 850px){
  .process{
    width: 800px;
  }
  .process-actual{
    width: 800px;
  }
  .intro-video{
    width: 500px;
  }
}

@media (max-width: 750px){
  .process{
    width: 700px;
  }
  .process-actual{
    width: 700px;
  }
  .intro-video{
    width: 500px;
  }
  .option{
    display: none;
  }
}
@media (max-width: 650px){
  .process{
    width: 600px;
  }
  .process-actual{
    width: 600px;
    height: 350px;
  }
  .intro-video{
    width: 400px;
  }
  .right-info{
    display: none;
  }
  .mission{
    width: 400px;
  }
  .profile{
    width: 200px;
  }
  .title{
    font-size: 40px ;
    width: 400px;
    margin-top: 20px;
  }
  .small-info{
    width: 400px;
  }
  .discover{
    width: 400px;
  }
  .slogan{
    width: 400px;
    font-size: 12px;
  }
  .process-right{
    width: 400px;
  }
  .step-info{
    text-align: center;
  }
  .testimonial-scroll{
    width: 400px;
  }
}
@media (max-width: 550px){
  .process{
    width: 400px;
  }
  .process-actual{
    width: 400px;
    height: 280px;
  }
  .process-left{
    width: 400px;
  }
  .step-info{
    font-size: 15px;
  }
}
@media (max-width: 450px){
  .process{
    width: 350px;
  }
  .process-actual{
    width: 350px;
    height: 250px;
    height: 280px;
  }
  .process-left{
    width: 350px;
  }
  .learn-more{
    font-size: 18px;
  }
  .intro-video{
    width: 350px;
  }
  .about-us{
    width: 350px;
  }
  .left-info{
    width: 350px;
  }
  .discover{
    font-size: 30px;
    width: 350px;
  }
  .mission{
    width: 350px;
  }
  .information-section-div{
    width: 350px;
    padding-left: 0px;
  }
  .profile{
    width: 150px;
  }
  .process-right{
    align-items: center;
    width: 350pxs;
  }
  .step-info{
    width: 350px;
    font-size: 15px;
  }
  .small-info{
    width: 350px;
    font-size: 15px;
  }
  .testimonial-scroll{
    width: 350px;
  }
  .testimonials{
    width: 350px;
  }
  .small-input{
    width: 150px;
  }
  .label-small{
    font-size: 12px;
  }
  .staff-title-new{
    font-size: 40px;
    margin-top: 10px;
  }
  .thanks{
    width: 350px;
  }
}

